import classes from './NoPhone.module.css'

const NoPhone = () => {

  const handleTTClick = () => {
    window.open('https://www.tiktok.com/@gekko.ind', '_blank'); // Открывает ссылку в новой вкладке
  };

  const handleInstClick = () => {
    window.open('https://www.instagram.com/gekko_indo', '_blank'); // Открывает ссылку в новой вкладке
  };

  return (
    <div className={classes.NoPhone}>
      <img src='/assets/noPhonebg.png' alt='bg' className={classes.bg}/>
      <div className={classes.buttons}>
        <div className={classes.button} onClick={handleTTClick}>
          <img src='/assets/npTiktok.png' alt='tt' className={classes.tiktok}/>
          TikTok
        </div>
        <div className={classes.button} onClick={handleInstClick}>
          <img src='/assets/npInst.png' alt='inst' className={classes.inst}/>
          Instagram
        </div>
      </div>
    </div>
  )
}

export default NoPhone;